 import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CurveHero = ({ pageTitle, location }) => (
  <div className="relative bg-black shadow">
    <div className="absolute inset-0">
      <StaticImage
        className="w-full h-full object-cover"
        src="https://ordinaryseo.com.au/wp-content/uploads/2023/01/Wings_KCGM_Super-Pit_20210126-0790.jpg"
        alt=""
      />
      <div className="absolute inset-0 bg-black mix-blend-multiply background-black" aria-hidden="true" />
    </div>
    <div className="relative max-w-7xl mx-auto sm:py-24 px-4 pt-32 pb-8 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-extrabold tracking-tight text-center text-white sm:text-5xl lg:text-6xl">{pageTitle}</h1>
      <h2 className="text-2xl font-bold text-white text-center">{location}</h2>
    </div>
  </div>
)

export default CurveHero
