import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveHero from "../components/CurveHero"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const query = graphql`
 query {
    wpPage(slug: {eq: "future"}) {
      timeline {
        timeline {
          timelineContent
          timelineHeading
          timelineImage {
          slug
          uri
          srcSet
          sourceUrl
          
          localFile {
                id
                childImageSharp {
                  gatsbyImageData
                }
              }
          }
          project {
            ... on WpPage {
              id
              uri
              title
              acf:ACFProjects {
                content:projectContent {
                  ... on WpPage_Acfprojects_ProjectContent_TextAndImage {
                    fieldGroupName
                    text
                    stats: projectStats {
                      location
                      client
                      commodity
                      contract
                    }
                    rightImage {
                      gatsbyImage(width: 150, quality: 50)
                    }
                    asxLinks {
                      asxUrl {
                      title
                      url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const FutureTimeline = ({data}) => {
  // const Project = (props) => {

  //     const projectData = props.input
  //     const stats = projectData.acf.content[0].stats
  //     return (
  //       <div className="project-card flex flex-row flex-wrap">
  //         <div className="card-title w-full">
  //           <h6>{projectData.title}</h6>
  //         </div>
  //         <div className="project-text w-2/3">
  //           <div className="project-info ">
  //             <ul class="flex flex-row flex-wrap">
  //               <li class="w-1/2">
  //                 <span className="stat-name">Location</span>
  //                 <span className="stat-val">
  //                   {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  //                       <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
  //                   </svg> */}
  //                   {stats.location}
  //                 </span>
  //               </li>
  //               <li>
  //                 <span className="icon"></span>
  //                 <span className="stat-name">Client</span>
  //                 <span className="stat-val">{stats.client} </span>
  //               </li>
  //               <li>
  //                 <span className="icon"></span>
  //                 <span className="stat-name">Commodity</span>
  //                 <span className="stat-val">{stats.commodity} </span>
  //               </li>
  //               <li>
  //                 <span className="icon"></span>
  //                 <span className="stat-name">Contract Duration</span>
  //                 <span className="stat-val">{stats.contract} </span>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //         <div class="project-link w-1/3 ">
  //           <Link to={projectData.uri} className="relative top-1/2">
  //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  //               <path fillRule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clipRule="evenodd" />
  //             </svg>
  //           </Link>
  //         </div>

  //       </div>
  //     )

  // }
    return (
      <Layout>
      <div className="futureProjects bg-orange-100">
      <CurveHero pageTitle="Future Projects" />
        <div className="overflow-x-hidden pt-18 bg-orange-100">
          <div className="timeline py-0">
            <div className="timeline__wrapper">
            <div className="timeline__single">
                {data.wpPage.timeline.timeline.map (({ timelineContent, timelineImage, timelineHeading, project }, index) => (
                  <div className="timeline-entry" key={index}>
                    <div className="timeline-entry--dot" data-sal="flip-up" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease-out-back"></div>
                      <div className="timeline-entry__right timeline__text timeline__col" data-sal="slide-right" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease">
                        <h2>{timelineHeading}</h2>
                        {project ?
                        <div className={`project-wrap flex flex-row flex-nowrap ${ index % 2 === 0 ? "justify-end" : "justify-start"}`}> 
                          <Link to={project.uri} className="timeline-entry__lbl normal-case no-underline">{timelineContent}</Link>
                          <span className="relative top-[14px] md:top-[2px] left-[2px]">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white md:text-gray-600 ">
                              <path fillRule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clipRule="evenodd" />
                            </svg>
                          </span>
                        </div>
                        :
                        <div className={`future-project-text text-black flex text-center justify-center ${index % 2 === 0 ? "md:justify-end" : "md:justify-start"}`}>
                          {timelineContent && 
                            <p className="text-sm md:text-lg font-bold text-gray-100 md:text-black w-fit">{timelineContent}</p>
                          }  
                        </div>
                        }
                      </div>
                    <div className="timeline-entry__left timeline__img timeline__col" data-sal="slide-left" data-sal-delay="300" data-sal-duration="1500" data-sal-easing="ease">
                      <GatsbyImage image={getImage(timelineImage.localFile)}  />
                    </div>
                  </div>
                ))}
            </div>
            </div>
          </div>
        </div>
      </div>
      </Layout>
    )

}
export const Head = () => <Seo title="Future"/>

export default FutureTimeline
